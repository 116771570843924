import { Controller } from '@hotwired/stimulus'

export default class WorkordersController extends Controller {
  static targets = ['metadata']

  addField () {
    const uuid = window.crypto.randomUUID()
    const fieldWrapper = document.createElement('div')
    fieldWrapper.classList.add('flex', 'gap-6', 'items-center', 'metadata-field')

    fieldWrapper.innerHTML = `
      <div class="flex items-center my-2">
        <input id="key_${uuid}" value="" class="form-control" required="required" type="text" name="metadata[][key]">
        <span class="mx-4">:</span>
        <input id="value_${uuid}" value="" class="form-control" required="required" type="text" name="metadata[][value]">
      </div>
      <a data-action="click->workorder-form#removeField" class="btn btn-secondar bg-error text-white h-fit" href="#">Remove</a>    `

    this.metadataTarget.appendChild(fieldWrapper)
  }

  removeField (event) {
    event.preventDefault()
    event.target.closest('.metadata-field').remove()
  }
}
