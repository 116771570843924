import { Controller } from '@hotwired/stimulus'
import FlashController from './flash_controller'

export default class VconnAssemblySetupController extends Controller {
  static values = { currentStep: Number }
  static targets = [
    'form',
    'stepContent',
    'stepLoader',
    'prevButton',
    'nextButton'
  ];

  showLoader (e) {
    this.stepContentTarget.classList.add('hidden')
    this.stepLoaderTarget.classList.remove('hidden')
    this.prevButtonTarget.disabled = true
    this.nextButtonTarget.disabled = true
    this.nextButtonTarget.classList.add('!text-gray-400')

    if (e && e.currentTarget) {
      const form = e.currentTarget.closest('form')
      form.requestSubmit()
    }
  }

  submitForm (e) {
    if (this.formTarget.checkValidity()) {
      this.showLoader()
    }

    let currentStep = this.currentStepValue
    const isNext = e.currentTarget.dataset.isNext === 'true'
    if (isNext) {
      currentStep += 1
      if (currentStep === 7) {
        FlashController.prototype.renderFlash('Syncing Data to GoCodes, it may take some time.', 'success')
      }
    } else {
      currentStep -= 1
      this.formTarget.noValidate = true
    }
    const extraInput = document.createElement('input')
    extraInput.type = 'hidden'
    extraInput.name = 'current_step'
    extraInput.value = currentStep
    this.formTarget.appendChild(extraInput)
    this.formTarget.requestSubmit()
  }
}
